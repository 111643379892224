import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet";
import profile_pic_1 from "../tmpImages/profile/profile_pic_1.jpg";
import profile_pic_2 from "../tmpImages/profile/profile_pic_2.jpg";
import profile_pic_3 from "../tmpImages/profile/profile_pic_3.jpg";
import profile_pic_4 from "../tmpImages/profile/profile_pic_4.jpg";
import profile_pic_5 from "../tmpImages/profile/profile_pic_5.jpg";
import profile_pic_6 from "../tmpImages/profile/profile_pic_6.jpg";
import profile_pic_7 from "../tmpImages/profile/profile_pic_7.jpg";
import { Button, Form, Image } from "react-bootstrap";
import {
  AshbyLogo,
  GreenhouseLogo,
  LeverLogo,
  NoisyIcon,
  WorkableLogo,
} from "../components/Icons";
import employer_screenshot_1 from "../tmpImages/employer_screenshot_1.png";
import employer_screenshot_2 from "../tmpImages/employer_screenshot_2.png";
import employer_screenshot_3 from "../tmpImages/employer_screenshot_3.png";
import employer_screenshot_5 from "../tmpImages/employer_screenshot_5.png";
import { sendEmployerEmail } from "../components/SendEmail";
import PropTypes from "prop-types";

Employers.propTypes = {
  employerScrollToRef: PropTypes.object.isRequired,
};

export function Employers({ employerScrollToRef }) {
  const scrollDown = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sendEmployerEmail(
      e.target.formBasicEmail.value,
      e.target.formBasicCompany.value,
      e.target.atsLink.value,
      e.target.formBasicMessage.value,
      "employer-sign-up-button",
      "employer-submit-success",
      "employer-submit-error"
    );
  };

  return (
    <div className="off-white-background">
      <Helmet>
        <title>Vire: Employers</title>
      </Helmet>
      <Container fluid>
        <div className="purple-section">
          <Row className="vire-employer-section">
            <Col xs={12} sm={12} md={6} lg={6} className="vire-employer-header">
              <h1 className="job-h1 find-your-match-header">
                Find your perfect candidate match
              </h1>
              <p className="vire-header-copy">
                Seamless holistic matching between candidates and teams, powered
                by AI.
              </p>
              <div>
                <Button
                  className="vire-sign-up-button vire-category-button"
                  onClick={() => scrollDown(employerScrollToRef)}
                >
                  Start Now
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="candidate-img-container">
                <Row>
                  <Image className="candidate-img" src={profile_pic_1} />
                  <Image className="candidate-img" src={profile_pic_4} />
                  <Image className="candidate-img" src={profile_pic_7} />
                </Row>
                <Row className="candidate-img-middle-row">
                  <Image className="candidate-img" src={profile_pic_3} />
                  <Image className="candidate-img" src={profile_pic_5} />
                </Row>
                <Row className="candidate-img-last-row">
                  <Image className="candidate-img" src={profile_pic_2} />
                  <Image className="candidate-img" src={profile_pic_6} />
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <h2 className="vire-framework-header">
            Our <span className="job-matching-word-emphasis">Holistic</span>{" "}
            Assessment Framework
          </h2>
          <div className="vire-framework-container">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className="vire-framework-col"
              >
                <div className="vire-framework-item">
                  <div className="vire-framework-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      width={60}
                      height={60}
                      stroke="#5271ff"
                    >
                      <path d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                    </svg>
                  </div>
                  <h3>Expertise Fit</h3>
                  <p className="vire-framework-item-text">
                    Match candidate capabilities with your company's unique
                    goals and challenges.
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className="vire-framework-col"
              >
                <div className="vire-framework-item">
                  <div className="vire-framework-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      width={60}
                      height={60}
                      stroke="#5271ff"
                    >
                      <path d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                    </svg>
                  </div>
                  <h3>Motivational Fit</h3>
                  <p className="vire-framework-item-text">
                    Find candidates whose passions and ambitions resonate with
                    your mission and vision.
                  </p>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={4}
                className="vire-framework-col"
              >
                <div className="vire-framework-item">
                  <div className="vire-framework-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      width={60}
                      height={60}
                      strokeWidth="1.5"
                      stroke="#5271ff"
                    >
                      <path d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                  </div>
                  <h3>Environmental Fit</h3>
                  <p className="vire-framework-item-text">
                    Assess cultural compatibility and work style preferences for
                    seamless team integration and long-term success.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="vire-silence-noise-container">
          <div className="value-prop-container">
            <NoisyIcon size="50" />
            <h2>Silence the Noise</h2>
            <div className="employer-silence-noise-section">
              <p>
                Focus on the most promising candidates by filtering out
                irrelevant matches and zeroing in on those who truly stand out.
              </p>
            </div>
          </div>
          <div className="value-prop-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width={50}
              height={50}
              stroke="currentColor"
            >
              <path d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
            </svg>

            <h2>Save Time in Interviews</h2>
            <div className="employer-silence-noise-section">
              <p>
                Equipped with deep insights, recruiters can streamline
                interviews and phone screens, keeping conversations focused and
                meaningful.
              </p>
            </div>
          </div>
          <div className="value-prop-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              width={50}
              height={50}
              stroke="currentColor"
            >
              <path d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
            </svg>

            <h2>Improve Retention and Performance</h2>
            <div className="employer-silence-noise-section">
              <p>
                Ensure an all-around fit for your team, leading to higher job
                satisfaction and better long-term outcomes.
              </p>
            </div>
          </div>
        </div>
        <div className="employers-selling-points-container">
          <h2 className="unique-process-header">
            Our <span className="job-matching-word-emphasis">Unique</span>{" "}
            Process
          </h2>
          <p className="vire-header-copy unique-process-subheader">
          Vire’s approach combines multimodal data aggregation with advanced LLMs to deliver unparalleled insights into candidate fit.
          </p>
          <Row className="employers-selling-points-row">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className="employer-point-column"
            >
              <div className="employer-point-container">
                <h3 className="employers-selling-point">
                  <b>Insightful Open Prompts</b>
                </h3>
                <p>
                  Uncover unique perspectives through open-ended, personalized
                  questions.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className="employer-point-column"
            >
              <Image
                className="employer-screenshot"
                src={employer_screenshot_2}
              />
            </Col>
          </Row>
          <Row className="employers-selling-points-row">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={{ span: 5, order: "last" }}
              className="employer-point-column"
            >
              <div className="employer-point-container">
                <h3 className="employers-selling-point">
                  <b>Resume Enhancement</b>
                </h3>
                <p>
                  Extract deeper insights from candidates’ experience and
                  qualifications.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className="employer-point-column"
            >
              <Image
                className="employer-screenshot"
                src={employer_screenshot_1}
              />
            </Col>
          </Row>
          <Row className="employers-selling-points-row">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className="employer-point-column"
            >
              <div className="employer-point-container">
                <h3 className="employers-selling-point">
                  <b>Dynamic Multiselect</b>
                </h3>
                <p>
                  Adaptive question sets that gather actionable, structured
                  data.
                </p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} className="employer-point-column">
              <Image
                className="employer-screenshot"
                src={employer_screenshot_5}
              />
            </Col>
          </Row>
          <Row className="employers-selling-points-row">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="employer-point-column"
            >
              <div className="employer-point-container">
                <h3 className="employers-selling-point">
                  <b>Social Link Integration</b>
                </h3>
                <p>
                  Curate a complete picture of candidates with data pulled from
                  public profiles.
                </p>
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={{ span: 6, order: "first" }}
              className="employer-point-column employer-point-column-center"
            >
              <Image
                className="employer-screenshot"
                src={employer_screenshot_3}
              />
            </Col>
          </Row>
        </div>
        <div>
          <h2 className="vire-framework-header">
            Our{" "}
            <span className="job-matching-word-emphasis">Game-Changing</span>{" "}
            Solutions
          </h2>
          <div className="vire-framework-container">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="vire-framework-col"
              >
                <div className="vire-solution-item">
                  <h3 className="vire-solution-header">
                    AI-Powered Pipeline Processing
                  </h3>
                  <p className="vire-framework-item-text">
                    Revolutionize your hiring funnel with intelligent automation
                    that identifies top talent faster and more effectively.
                  </p>
                  <h4 className="vire-solution-features-header">
                    Key Features:
                  </h4>
                  <ul className="no-bullets">
                    <li className="solution-list">
                      ✔️ Automated candidate ranking
                    </li>
                    <li className="solution-list">
                      ✔️ Customizable assessment workflows
                    </li>
                    <li className="solution-list">
                      ✔️ Multidimensional candidate insights
                    </li>
                    <li className="solution-list">✔️ Simple ATS integration</li>
                  </ul>
                  <Button
                    className="vire-category-button"
                    onClick={() => scrollDown(employerScrollToRef)}
                  >
                    Explore AI-Powered Pipeline Processing
                  </Button>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                className="vire-framework-col"
              >
                <div className="vire-solution-item">
                  <h3 className="vire-solution-header">
                    Precision Matching Platform
                  </h3>
                  <p className="vire-framework-item-text">
                    Access a curated pool of exceptional candidates perfectly
                    aligned with your needs, values, and vision.
                                  </p>
                                  <h4 className="vire-solution-features-header">
                    Key Features:
                  </h4>
                  <ul className="no-bullets">
                    <li className="solution-list">
                      ✔️ Holistic, mutual fits
                    </li>
                    <li className="solution-list">
                      ✔️ Deep candidate insights
                    </li>
                    <li className="solution-list">
                      ✔️ Attract and retain top talent
                    </li>
                    <li className="solution-list">✔️ Align on company mission and culture</li>
                  </ul>
                  <Button
                    className="vire-category-button"
                    onClick={() => scrollDown(employerScrollToRef)}
                  >
                    Explore Precision Matching Platform
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="ats-logos-section">
          <h2>Integrations with your ATS</h2>
          <Row className="ats-logos-container">
            <Col>
              <AshbyLogo size="120" />
            </Col>
            <Col>
              <LeverLogo size="120" />
            </Col>
            <Col>
              <GreenhouseLogo size="120" />
            </Col>
            <Col>
              <WorkableLogo size="120" />
            </Col>
          </Row>
          <p>And more...</p>
        </div>
        <div className="employer-get-started-div" ref={employerScrollToRef}>
          <h2 className="employer-get-started-header">
            Ready to Build Extraordinary Teams?
          </h2>
          <p>Contact us to learn more and tell us what you are looking for.</p>
          <div className="employer-get-started-form-container">
            <Form className="employer-get-started-form" onSubmit={handleSubmit}>
              <Form.Group
                className="employer-get-started-form-group"
                controlId="formBasicEmail"
              >
                <Form.Label>Company email address*</Form.Label>
                <Form.Control type="email" placeholder="Enter email" required />
              </Form.Group>
              <Form.Group
                className="employer-get-started-form-group"
                controlId="formBasicCompany"
              >
                <Form.Label>Company name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company"
                  required
                />
              </Form.Group>
              <Form.Group
                className="employer-get-started-form-group"
                controlId="atsLink"
              >
                <Form.Label>ATS job board link</Form.Label>
                <Form.Control type="url" placeholder="Enter ats job link" />
              </Form.Group>
              <Form.Group
                className="employer-get-started-form-group"
                controlId="formBasicMessage"
              >
                <Form.Label>Message*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter message"
                  required
                />
              </Form.Group>
              <p className="signup-error-message" id="employer-submit-error">
                There was an error. Try again or send us a direct email.
              </p>
              <p
                className="signup-complete-message"
                id="employer-submit-success"
              >
                Thanks! We'll be in touch soon.
              </p>
              <Button
                id="employer-sign-up-button"
                className="vire-sign-up-button vire-category-button"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </div>
          <p className="employer-reach-out">
            Or reach out at{" "}
            <a className="purple-link" href="mailto:hello@joinvire.com">
              hello@joinvire.com
            </a>
            !
          </p>
        </div>
      </Container>
    </div>
  );
}
